.sidebar {
    width: 60px;
    height: 100vh;
    background-color: var(--sidebar);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: width 0.3s ease;
    z-index: 1000;
    float: left;
}

.sidebar ul li a img {
    display: inline-block;
    max-width: 24px;
    vertical-align: middle;
}

.sidebar:hover {
    width: 220px;
}

.sidebar:hover .issure-icon-active {
    width: 185px !important;
}

.sidebar:hover .sidebar-item-content {
    width: 185px !important;
}

.sidebar-icons {
    padding: 15px 0 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-logo {
    position: relative;
    margin-bottom: 50px;
}

.issure-icon, .issure-icon:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.issure-icon{
    opacity: .5;
    gap: 8px;
}
.issure-icons {
    margin: 7px 5px;
    background-color: initial;
    margin: 7px 5px;
    background-color: initial;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin: 0;
}

/* .sidebar:hover ul:active {
    background-color: aqua;
    width: 200px !important;
} */
.sidebar-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 7px;
    color: var(--sidebar-span-text);
    text-decoration: none;
    /* width: 185px; */
    transition: width 0.3s;
}
    
.sidebar-item img {
    width: 24px;
    height: 24px;
}
.dialogLogo {
  background-color: #018E82;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-weight: bolder;
  stroke-width: 1px;
  stroke: rgba(1, 142, 130, 0.20);
}

.sidebar-item-label {
    display: none;
    margin-left: 15px;
    color: white;
    font-size: 14px;
}

.sidebar:hover .sidebar-item-label {
    display: inline-block;
}

.sidebar-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
}

.sidebar-item-profile {
    background-color: var(--sidebar-profile-bg);
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50px;
    color: #004d40;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}
a{
    text-decoration: none;
}

.issure-icon-active, .issure-icon-active:hover {
    background-color: #1a534e;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
}
.issure-icon-active {
    width: 44px;
    height: 44px;
    opacity: 1;
    grid-gap: 8px;
    gap: 8px;
    transition: width 0.3s;
}
.issure-icon-active span{
  color: var(--sidebar-active-text) !important;
}
/* Add hover effects for smooth transitions */
.sidebar-item:hover {
    background-color: #1a534e;
}

.notificationcolor {
    font-size: 24px;
    color: #ccc;
}

.sidebar-icons ul {
    list-style-type: none !important;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none !important;
}

.sidebar-icons li {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Ensures text is aligned with icons */
        /* margin-bottom: 20px; */
        /* Adds space between each icon-item */
        list-style-type: none !important;
}

.sidebar-icons img {
    width: 40px;
    /* height: 30px; */
    /* Adds spacing between the icon and text */
}

.sidebar-icons span {
    font-size: 14px;
    color: var(--sidebar-span-text);
    /* Adjust this based on the sidebar color scheme */
}

.logo-intain-hidden {
    display: none;
    /* Hide IntainLogo by default */
}

/* Show IntainLogo on sidebar hover */
.sidebar:hover .logo-intain-hidden {
    display: block;
    width: 88px;
    height: 44px;
    /* Show IntainLogo when sidebar is hovered */
}

.sidebar:hover .logo-intain {
    display: none;
    /* Hide Intan_AI when sidebar is hovered */
}

.sidebar-item-content {
    opacity: 0.5;
    display: flex;
    gap: 8px;
}

.sidebar-item:hover {
    opacity: 0.5;
    cursor: pointer;
    border-radius: 10px;
    padding: 8px 7px;
    /* width: 185px !important; */
}

/* Show Content on sidebar hover */
.content-show {
    display: none;
    /* Hide IntainLogo by default */
}

.sidebar:hover .content-show {
    display: block;
    /* width: 110px; */
    width: 132px;
    text-align: justify;
    /* Show Content when sidebar is hovered */
}

.custom-popover .ant-popover-inner {
    border-radius: 8px;
    border: 1px solid var(--text-color);
    background: var(--background-color);
    padding: 0;
  }
.ant-popover .ant-popover-content{
  min-width: 235px;
  border-radius: 8px;
  border: 1px solid var(--text-color);
  background: var(--background-color);
}
  
  .ant-popover-arrow::after{
    border: 1px solid var(--popover-arrow);
  }

  .custom-popover-profile .ant-popover-inner {
    border-radius: 8px;
    border: 1px solid var(--text-color);
    background: var(--background-color);
  }
  .ant-popover .ant-popover-inner{
    background: var(--background-color);
  }

.dialogProfileName{
  color: var(--dialog-profile-name);
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dialogProfileNameP{
  color: var(--dialog-profile);
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}
.dialogProfileEmail {
  color: var(--text-color);
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 10px;
}
p.logout_nav {
  color: var(--dialog-profile);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px 0;
  cursor: pointer;
}

.light-dark-switch{
  width: 125px;
  height: 40px;
  align-items: center;
  justify-content: space-around;
}
.light-dark-switch>label{
  font-family: var(--mulish-font);
  color: var(--text-color);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-switch{
  color: #000;
  border: 1px solid;
  height: 18px;
  min-width: 30px;
  background-color: white;
}

.ant-switch-handle{
  height: 14px !important;
  width: 14px !important;
  top: 1px !important;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 16px);
}
.ant-switch-handle::before{
  background-color: #212121 !important;
}

.ant-switch.ant-switch-checked{
  background-color: #fff !important;
}

.ant-switch:hover:not(.ant-switch-disabled){
  background-color: #fff !important;
}
/* when hover switch will change color */
.ant-switch:hover:not(.ant-switch-disabled) .ant-switch-handle::before {
  background-color: #000 !important;
}
.ant-popover-placement-rightBottom>.ant-popover-arrow{
    left: 1px;
}
/* popover bg blur */
.blur-background {
    filter: blur(3px);
    transition: filter 0.3s ease-in-out;
    pointer-events: none;
    z-index: -1;
}

/* darkmode switch */
.darkmode-label {
    width: 40px; /* Reduced width */
    height: 20px; /* Reduced height */
    position: relative;
    display: block;
    background-color: #03dac6;
    border-radius: 10px; /* Adjusted for smaller size */
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.4),
      inset 0px -1px 3px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;
  }
  
  .darkmode-label::after {
    content: "";
    width: 16px; /* Reduced size */
    height: 16px; /* Reduced size */
    position: absolute;
    top: 2px; /* Adjusted for smaller size */
    left: 2px; /* Adjusted for smaller size */
    background: linear-gradient(180deg, #ffffff, #ffffff);
    border-radius: 50%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
  }
  
  #darkmode-toggle {
    width: 0;
    height: 0;
    visibility: hidden;
  }
  
  #darkmode-toggle:checked + .darkmode-label {
    background-color: #017268;
  }
  
  #darkmode-toggle:checked + .darkmode-label::after {
    left: 90%; /* Adjusted for smaller size */
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #212121);
  }
  
  /* Adjusted size for sun and moon icons */
  .sun,
  .moon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px; 
    height: 15px; 
    transition: 0.3s;
    z-index: 100;
  }
  
  .moon {
    left: 21px; /* Adjusted position 2*/
    opacity: 0; /* Moon is visible when unchecked 1*/
  }
  
  .sun {
    right: 23px; /* Adjusted position  4*/
    opacity: 1; /* Sun is hidden when unchecked 0*/
  }
  
  #darkmode-toggle:checked + .darkmode-label .moon {
    opacity: 1; /* Moon hides when checked */
  }
  
  #darkmode-toggle:checked + .darkmode-label .sun {
    opacity: 0; /* Sun shows when checked */
  }
  
  .darkmode-label:active::after {
    width: 13px;
    height: 13px;
  }
  
  :where(.css-dev-only-do-not-override-apn68).ant-popover
  .ant-popover-arrow:before {
    background-color: var(--background-color);
    
}

/* @media (max-width: 480px) {
  .sidebar {
    width: 600px;
  }
} */
